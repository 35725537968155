import { createAction, props } from '@ngrx/store';

export const downloadRequest = createAction(
  '[Download Rates] Download Request',
  props<{
    propertyId: number;
    channelId: number;
    dateFrom?: string | Date;
    dateTo?: string | Date;
  }>(),
);

export const downloadSuccess = createAction(
  '[Download Rates] Download Success',
);

export const downloadFailure = createAction(
  '[Download Rates] Download Failure',
  props<{ error: any }>(),
);

export const resetState = createAction('[Download Rates] Reset State');

import { createAction, props } from '@ngrx/store';
import { Hooks } from '../../helpers';

export const downloadRequest = createAction(
  '[Download Rooms] Download Request',
  props<{
    propertyId: number;
    channelId: number;
    hooks: Hooks;
  }>(),
);

export const downloadSuccess = createAction(
  '[Download Rooms] Download Success',
  props<{
    propertyId: number;
    channelId: number;
    data: any;
  }>(),
);

export const downloadFailure = createAction(
  '[Download Rooms] Download Failure',
  props<{ error: any }>(),
);

export const checkRoomDeletedRequest = createAction(
  '[Download Rooms] Check Rooms Deleted Request',
  props<{
    propertyId: number;
    channelId: number;
    hooks?: Hooks;
  }>(),
);

export const checkRoomDeletedSuccess = createAction(
  '[Download Rooms] Check Rooms Deleted Success',
  props<{
    propertyId: number;
    channelId: number;
    data: any;
  }>(),
);

export const checkRoomDeletedFailure = createAction(
  '[Download Rooms] Check Rooms Deleted Failure',
  props<{ error: any }>(),
);
export const resetState = createAction('[Download Rooms] Reset State');

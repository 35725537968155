import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { TranslateService } from '@ngx-translate/core';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import { ErrorHandlerService } from '../../core/services/error-handler.service';
import { DownloadRatesService } from '../../services';
import { NotificationService } from '../../ui/services/notification.service';

import * as fromActions from './actions';

@Injectable()
export class DownloadRatesStoreEffects {
  constructor(
    private dataService: DownloadRatesService,
    private actions$: Actions,
    private errorHandler: ErrorHandlerService,
    private _notifications: NotificationService,
    private _translate: TranslateService,
  ) {}

  download$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.downloadRequest),
      switchMap(({ propertyId, channelId, dateFrom, dateTo }) =>
        this.dataService
          .downloadRates(propertyId, channelId, dateFrom, dateTo)
          .pipe(
            map(() => {
              this._notifications.push({
                title: this._translate.instant('done'),
                content: this._translate.instant(
                  'notifications.generic_success',
                  {
                    param: this._translate.instant('rates_and_availabilities'),
                  },
                ),
                type: 'success',
              });
              return fromActions.downloadSuccess();
            }),
            catchError((error) => {
              this.errorHandler.handle(error);
              return of(fromActions.downloadFailure(error));
            }),
          ),
      ),
    ),
  );
}
